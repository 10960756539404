<template>
  <a-modal
    :title="isEdit ? '编辑广告位' : '新增广告位'"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    width="800px"
  >
    <a-form :form="form">
      <a-form-item label="唯一标识">
        <a-input
          :maxLength="30"
          v-decorator="[
            'uniqueKey',
            {
              initialValue: record.uniqueKey || '',
              rules: [
                { required: true, message: '请输入唯一标识', trigger: 'blur' },
              ],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('uniqueKey') }}/30</span
        >
      </a-form-item>
      <a-form-item label="位置描述">
        <a-input
          :maxLength="30"
          v-decorator="[
            'position',
            {
              initialValue: record.position || '',
              rules: [
                { required: true, message: '输入位置描述', trigger: 'blur' },
              ],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('position') }}/30</span
        >
      </a-form-item>
      <a-form-item label="规格大小">
        <div class="input_double">
          <a-input
            placeholder="宽"
            v-decorator="[
              'width',
              {
                initialValue: record.width || '',
                rules: [{ required: true, message: '输入宽度' }],
              },
            ]"
          ></a-input>
          <a-icon type="close" style="margin-left: 10px; margin-right: 10px" />
          <a-input
            placeholder="高"
            v-decorator="[
              'height',
              {
                initialValue: record.height || '',
                rules: [{ required: true, message: '输入高度' }],
              },
            ]"
          ></a-input>
        </div>
      </a-form-item>
      <a-form-item label="展现形式">
        <a-radio-group
          v-decorator="[
            'type',
            {
              initialValue: record.type || 0,
              rules: [{ required: true, message: '选择形式' }],
            },
          ]"
        >
          <a-radio :value="0"> Banner </a-radio>
          <a-radio :value="1"> 弹窗 </a-radio>
          <a-radio :value="2"> Feed流 </a-radio>
          <a-radio :value="3"> 开屏 </a-radio>
          <a-radio :value="4"> 公告 </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="状态">
        <a-switch
          v-decorator="['enabled']"
          checked-children="开"
          un-checked-children="关"
          :default-checked="record.enabled ? true : false"
        />
      </a-form-item>

      <a-form-item label="推送对象">
        <a-radio-group
          @change="pushObjectChange"
          v-decorator="[
            'scope',
            {
              initialValue: record.pushConfig ? record.pushConfig.scope : 'ALL',
              rules: [{ required: true, message: '请选择点击事件' }],
            },
          ]"
        >
          <a-radio value="ALL">所有用户</a-radio>
          <a-radio value="NEW_USER">新用户（注册时间在3个月内）</a-radio>
          <a-radio value="OLD_USER">老用户</a-radio>
          <a-radio value="TENANT_USER"
            >特定租户用户
            <span
              v-if="pushObjectType == 'TENANT_USER'"
              style="margin-left: 15px; color: blue"
              @click="openSelectTenant"
              >选择租户</span
            ></a-radio
          >
        </a-radio-group>
      </a-form-item>
      <a-form-item label="已选租户" v-if="pushObjectType == 'TENANT_USER'">
        <div v-for="item in selectedTenant" :key="item">
          {{ item.name }}({{ item.id }})
        </div>
      </a-form-item>

      <a-form-item label="推送时间">
        <a-radio-group
          @change="pushTimeChange"
          v-decorator="[
            'pushTime',
            {
              initialValue: pushTimeType || 0,
              rules: [{ required: true, message: '请选择推送时间' }],
            },
          ]"
        >
          <a-radio :value="0">全年</a-radio>
          <a-radio :value="1">自定义</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="" v-if="pushTimeType == 1">
        <a-range-picker
          v-decorator="[
            'time',
            {
              rules: [{ required: true, message: '请先择推送时间' }],
              initialValue: timeArr,
            },
          ]"
        >
        </a-range-picker>
      </a-form-item>

      <a-form-item label="允许cookies记住关闭">
        <!-- <a-switch
          v-decorator="['rememberClose']"
          checked-children="开"
          un-checked-children="关"
          :default-checked="record.rememberClose ? true : false"
        /> -->

        <a-switch
          checked-children="开"
          un-checked-children="关"
          v-decorator="[
            'isRemember',
            {
              initialValue: record.isRemember || false,
              valuePropName: 'checked',
            },
          ]"
        />
      </a-form-item>

      <a-form-item label="配置频道和广告">
        <a-select
          placeholder="请选择频道（可多选）"
          mode="multiple"
          v-decorator="[
            'channelIds',
            {
              initialValue: record.channelIds
                ? JSON.parse(record.channelIds) || []
                : [],
            },
          ]"
        >
          <a-select-option
            v-for="item in channelList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
    <my-transfer
      :visible.sync="openVisible"
      :selectedIds="tenantIds"
      v-if="openVisible"
      title="选择租户"
      @refresh="refresh"
      @ok="transferOk"
    ></my-transfer>
  </a-modal>
</template>

<script>
import myTransfer from '@/components/transfer/myTransfer.vue'

export default {
  name: 'AddAdvertisePosition',
  components: { myTransfer },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // console.log(this.isEdit)
    // console.log(this.record)
    this.init()
  },
  data() {
    return {
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this),
      isEdit: Object.keys(this.record).length > 0,
      channelList: [],
      pushTimeType: 0,
      pushObjectType: 'ALL',
      openVisible: false,
      tenantIds: [],
      timeArr: [],
      allTenantList: [],
    }
  },
  computed: {
    selectedTenant() {
      return this.allTenantList.filter((item) => {
        return this.tenantIds.includes(item.id)
      })
    },
  },
  methods: {
    init() {
      this.getChannelList()
      this.getAllTenantList()
      if (this.isEdit) {
        this.pushTimeType =
          Object.keys(this.record.pushConfig.startTime).length == 0 ? 0 : 1
        this.pushObjectType = this.record.pushConfig.scope || 'ALL'
        this.tenantIds = this.record.pushConfig.tenantIds || []
        if (this.pushTimeType) {
          this.timeArr = [
            this.$moment(this.record.pushConfig.startTime),
            this.$moment(this.record.pushConfig.endTime),
          ]
        }
      }
    },
    /* 租户列表 */
    async getAllTenantList() {
      this.allTenantList = []
      let res = await this.$api._getTenantInfoList({ page: 1, size: 10 })
      if (res.code == 0) {
        let total = res.data.totalElements
        let time = Math.ceil(total / 100)
        let timeArr = [...Array(time).keys()].map((item) => item + 1)

        console.log(timeArr, 'timeArr')
        for (let item of timeArr) {
          let _res = await this.$api._getTenantInfoList({
            // size: res.data.totalElements,
            size: 100,
            // page: 1,
            page: item,
          })
          console.log(item, 'item')
          if (_res.code == 0)
            this.allTenantList = [...this.allTenantList, ..._res.data.content]
          // if (item == time) this.getAlreadyAllocationTenantList()
        }

        // this.getAlreadyAllocationTenantList()
      }
    },
    refresh() {
      this.openVisible = false
    },
    /* 推送时间切换 */
    pushTimeChange(e) {
      this.pushTimeType = e.target.value
    },
    /* 推送对象切换 */
    pushObjectChange(e) {
      this.pushObjectType = e.target.value
    },
    transferOk(value) {
      this.tenantIds = value
    },
    /* 打开选择租户 */
    openSelectTenant() {
      console.log('AAA')
      this.openVisible = true
    },
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    handleOk() {
      // this.confirmLoading = true
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$set(values, 'enabled', values.enabled ? 1 : 0)
          let pushConfig = {}
          pushConfig.scope = values.scope
          pushConfig.isRemember = values.isRemember
          if (values.scope == 'TENANT_USER') {
            // this.$set(values, 'tenantIds', this.tenantIds)
            pushConfig.tenantIds = this.tenantIds
          } else {
            pushConfig.tenantIds = []
          }
          if (values.pushTime == 1) {
            // this.$set(
            //   values,
            //   'startTime',
            //   this.$moment(values.time[0]).format('YYYY-MM-DD')
            // )
            // this.$set(
            //   values,
            //   'endTime',
            //   this.$moment(values.time[1]).format('YYYY-MM-DD')
            // )
            pushConfig.startTime = this.$moment(values.time[0]).format(
              'YYYY-MM-DD 00:00:00'
            )
            pushConfig.endTime = this.$moment(values.time[1]).format(
              'YYYY-MM-DD 00:00:00'
            )
          } else {
            pushConfig.startTime = null
            pushConfig.endTime = null
          }
          this.$set(values, 'pushConfig', pushConfig)
          console.log(values)

          if (this.isEdit) {
            /* 修改广告位信息 */
            this.$set(values, 'id', this.record.id)
            this.updateAdvertisePosition(values).then((res) => {
              this.$message.success('修改成功')
              this.$emit('refresh')
            })
          } else {
            /* 新增额广告位 */
            this.addAdvertisePosition(values).then((res) => {
              this.$message.success('添加成功')
              this.$emit('refresh')
            })
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    /* 获取频道列表 */
    getChannelList() {
      return new Promise((resolve, reject) => {
        this.$api.channelList().then((res) => {
          // console.log(res, '频道列表')
          if (res.code === 0) {
            this.channelList = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 添加广告位 */
    addAdvertisePosition(data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementPositionCreated(data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '添加广告位')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改广告位 */
    updateAdvertisePosition(data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementPositionUpdate(data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改广告位')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .img {
  width: 102px;
  height: 102px;
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}

/deep/ .input_double {
  display: flex;
  align-items: center;
}
</style>
