var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑广告' : '新增广告',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"800px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[(!_vm.isEdit)?_c('a-form-item',{attrs:{"label":"所属频道"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'channelIds',
          {
            initialValue: _vm.record.channelIds || [],
            rules: [{ required: true, message: '选择频道' }],
          } ]),expression:"[\n          'channelIds',\n          {\n            initialValue: record.channelIds || [],\n            rules: [{ required: true, message: '选择频道' }],\n          },\n        ]"}],attrs:{"placeholder":"请选择频道（可多选）","mode":"multiple"}},_vm._l((_vm.channelList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"广告类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          {
            initialValue: _vm.record.type || 0,
            rules: [{ required: true, message: '请选择广告类型' }],
          } ]),expression:"[\n          'type',\n          {\n            initialValue: record.type || 0,\n            rules: [{ required: true, message: '请选择广告类型' }],\n          },\n        ]"}],on:{"change":_vm.advertiseTypeChange}},[_c('a-radio',{attrs:{"value":0}},[_vm._v("图片")]),_c('a-radio',{attrs:{"value":1}},[_vm._v("文字")])],1)],1),(_vm.advertiseType == 0)?_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.record.name || '',
            rules: [{ required: true, message: '输入标题', trigger: 'blur' }],
          } ]),expression:"[\n          'name',\n          {\n            initialValue: record.name || '',\n            rules: [{ required: true, message: '输入标题', trigger: 'blur' }],\n          },\n        ]"}],attrs:{"maxLength":30}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('name'))+"/30")])],1):_c('a-form-item',{attrs:{"label":"文字内容"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'content',
          {
            initialValue: _vm.record.content || '',
            rules: [{ required: true, message: '输入标题', trigger: 'blur' }],
          } ]),expression:"[\n          'content',\n          {\n            initialValue: record.content || '',\n            rules: [{ required: true, message: '输入标题', trigger: 'blur' }],\n          },\n        ]"}],attrs:{"maxLength":100}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('content'))+"/100")])],1),_c('a-form-item',{attrs:{"label":"点击事件"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'clickType',
          {
            initialValue: _vm.clickType || 0,
            rules: [{ required: true, message: '请选择点击事件' }],
          } ]),expression:"[\n          'clickType',\n          {\n            initialValue: clickType || 0,\n            rules: [{ required: true, message: '请选择点击事件' }],\n          },\n        ]"}],on:{"change":_vm.advertiseClickChange}},[_c('a-radio',{attrs:{"value":0}},[_vm._v("跳转链接")]),_c('a-radio',{attrs:{"value":1}},[_vm._v("无点击事件")])],1)],1),(_vm.clickType == 0)?_c('a-form-item',{attrs:{"label":"URL"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'clickUrl',
          {
            initialValue: _vm.record.clickUrl || '',
            rules: [{ required: true, message: '输入url', trigger: 'blur' }],
          } ]),expression:"[\n          'clickUrl',\n          {\n            initialValue: record.clickUrl || '',\n            rules: [{ required: true, message: '输入url', trigger: 'blur' }],\n          },\n        ]"}]})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"图片"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'qrFile',
          {
            initialValue: _vm.record.imageUrl || '',
            rules: [{ required: true, message: '上传图片' }],
          } ]),expression:"[\n          'qrFile',\n          {\n            initialValue: record.imageUrl || '',\n            rules: [{ required: true, message: '上传图片' }],\n          },\n        ]"}],attrs:{"accept":"image/gif, image/jpeg, image/png","name":"avatar","listType":"picture-card","multiple":false,"beforeUpload":_vm.beforeUpload,"showUploadList":false,"customRequest":_vm.fileUpload},on:{"change":_vm._handleChange}},[(_vm.imageUrl)?_c('img',{staticClass:"img",attrs:{"src":_vm.imageUrl,"alt":"avatar"}}):_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1)])],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', { initialValue: _vm.record.remark || '' }]),expression:"['remark', { initialValue: record.remark || '' }]"}],attrs:{"rows":3,"maxLength":200}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('remark'))+"/200")])],1),_c('a-form-item',{attrs:{"label":"审核状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['enabled', { initialValue: _vm.record.enabled }]),expression:"['enabled', { initialValue: record.enabled }]"}],attrs:{"checked-children":"开","un-checked-children":"关","defaultChecked":_vm.record.enabled ? true : false}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }