<template>
  <div class="advertisement">
    <a-card>
      <a-tabs default-active-key="1" @change="tabChange">
        <a-tab-pane key="1" tab="广告位"></a-tab-pane>
        <a-tab-pane key="2" tab="频道与广告"> </a-tab-pane>
      </a-tabs>
      <!-- 广告位内容 -->
      <advertise-position-tab v-if="tabNumber == 1"></advertise-position-tab>
      <channels-and-advertise-tab
        v-if="tabNumber == 2"
      ></channels-and-advertise-tab>
    </a-card>
  </div>
</template>

<script>
import advertisePositionTab from './modal/advertisePositionTab.vue'
import channelsAndAdvertiseTab from './modal/channelsAndAdvertiseTab.vue'
export default {
  name: 'AdvertisementPut',
  components: {
    advertisePositionTab,
    channelsAndAdvertiseTab,
  },
  data() {
    return {
      tabNumber: 1,
    }
  },
  methods: {
    tabChange(value) {
      // console.log(value)
      this.tabNumber = value
    },
  },
}
</script>

<style lang="less" scoped>
.advertisement {
  min-height: 99%;
  background: #fff;
}

/deep/ .ant-card-bordered {
  border-bottom: none;
}
</style>
