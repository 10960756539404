import { fileApi } from '@/api/servers/fileApi.js'
import SparkMD5 from 'spark-md5'

/* 
  file(文件)
  fileType(上传得文件类型)： 1图片，2视频，3， 4apk文件
*/

/* 文件预上传 */
export function filePreUpload(file, fileType) {
  return new Promise(async (resolve, reject) => {
    let fileMd5 = await getFileMd5(file)
    let preData = {
      fileSize: file.size,
      fileType: fileType,
      isMedia: 0,
      originFileName: file.name,
      md5: fileMd5,
      tenantId: sessionStorage.getItem('tid'),
      isLimit: 0 /* 是否限制容量 */,
    }
    fileApi
      .preUpload(preData)
      .then((res) => {
        if (res.code === 0) {
          resolve({ ...res.data, preData })
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/* 文件参MD5数获取 */
export function getFileMd5(file) {
  return new Promise((resolve, reject) => {
    let spark = new SparkMD5.ArrayBuffer()
    let md5value = ''
    // const blob = new Blob([fileData], { type: 'text/plain' })
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      spark.append(reader.result)
      md5value = spark.end()
      console.log(md5value)
      resolve(md5value)
    })
    reader.readAsArrayBuffer(file)
  })
}

/* 文件上传 */
export function fileUpload(preUploadRes, file) {
  /* 判断上传文件类型，使用对应的接口（apk文件需要携带特定请求头） */
  if (preUploadRes.fileType !== 4) {
    return new Promise((resolve, reject) => {
      let { realOperationUrl, fileFormat, fileUrl } = preUploadRes
      if (realOperationUrl) {
        /* 上传文件 */
        fileApi
          ._newUploadFile(realOperationUrl, file)
          .then((res) => {
            resolve({ fileUrl, fileFormat, ...preUploadRes.preData })
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        /* 文件已存在，无需再次上传 */
        resolve({ fileUrl, fileFormat, ...preUploadRes.preData })
      }
    })
  } else {
    return new Promise((resolve, reject) => {
      let { realOperationUrl, fileFormat, fileUrl } = preUploadRes
      if (realOperationUrl) {
        /* 上传文件 */
        fileApi
          .newUploadFile(realOperationUrl, file)
          .then((res) => {
            resolve({ fileUrl, fileFormat, ...preUploadRes.preData })
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        /* 文件已存在，无需再次上传 */
        resolve({ fileUrl, fileFormat, ...preUploadRes.preData })
      }
    })
  }
}

/* 文件保存 */
export function saveFile(data) {
  return new Promise((resolve, reject) => {
    let saveData = {
      fileDescription: '',
      fileFormat: data.fileFormat,
      fileName: data.originFileName,
      fileSize: data.fileSize,
      fileType: data.fileType,
      fileUrls: [
        {
          url: data.fileUrl,
        },
      ],
      isMedia: 0,
      remark: '',
      tenantId: sessionStorage.getItem('tid'),
      md5: data.md5,
      isLimit: 0,
    }

    // fileApi.newSaveFile(saveData).then(res => {
    fileApi.saveFileReturnID(saveData, 0).then((res) => {
      console.log(res, '保存文件，返回id')
      if (res.code == 0) {
        resolve({ ...data, fileId: res.data })
      } else {
        reject(res.message)
      }
    })
  })
}
export function fullFileUpload(file, fileType = 1) {
  return new Promise((resolve, reject) => {
    filePreUpload(file, fileType).then((res) => {
      fileUpload(res, file).then((res) => {
        saveFile(res).then((res1) => {
          resolve(res1)
        })
      })
    })
  })
}
