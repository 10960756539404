<template>
  <a-modal
    :title="isEdit ? '编辑广告' : '新增广告'"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    width="800px"
  >
    <a-form :form="form">
      <a-form-item label="所属频道" v-if="!isEdit">
        <a-select
          placeholder="请选择频道（可多选）"
          mode="multiple"
          v-decorator="[
            'channelIds',
            {
              initialValue: record.channelIds || [],
              rules: [{ required: true, message: '选择频道' }],
            },
          ]"
        >
          <a-select-option
            v-for="item in channelList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="广告类型">
        <a-radio-group
          @change="advertiseTypeChange"
          v-decorator="[
            'type',
            {
              initialValue: record.type || 0,
              rules: [{ required: true, message: '请选择广告类型' }],
            },
          ]"
        >
          <a-radio :value="0">图片</a-radio>
          <a-radio :value="1">文字</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="标题" v-if="advertiseType == 0">
        <a-input
          :maxLength="30"
          v-decorator="[
            'name',
            {
              initialValue: record.name || '',
              rules: [{ required: true, message: '输入标题', trigger: 'blur' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('name') }}/30</span
        >
      </a-form-item>
      <a-form-item label="文字内容" v-else>
        <a-input
          :maxLength="100"
          v-decorator="[
            'content',
            {
              initialValue: record.content || '',
              rules: [{ required: true, message: '输入标题', trigger: 'blur' }],
            },
          ]"
        ></a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('content') }}/100</span
        >
      </a-form-item>

      <a-form-item label="点击事件">
        <a-radio-group
          @change="advertiseClickChange"
          v-decorator="[
            'clickType',
            {
              initialValue: clickType || 0,
              rules: [{ required: true, message: '请选择点击事件' }],
            },
          ]"
        >
          <a-radio :value="0">跳转链接</a-radio>
          <a-radio :value="1">无点击事件</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="URL" v-if="clickType == 0">
        <a-textarea
          v-decorator="[
            'clickUrl',
            {
              initialValue: record.clickUrl || '',
              rules: [{ required: true, message: '输入url', trigger: 'blur' }],
            },
          ]"
        ></a-textarea>
      </a-form-item>
      <a-form-item label="图片">
        <a-upload
          accept="image/gif, image/jpeg, image/png"
          name="avatar"
          listType="picture-card"
          :multiple="false"
          :beforeUpload="beforeUpload"
          :showUploadList="false"
          @change="_handleChange"
          v-decorator="[
            'qrFile',
            {
              initialValue: record.imageUrl || '',
              rules: [{ required: true, message: '上传图片' }],
            },
          ]"
          :customRequest="fileUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" class="img" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea
          :rows="3"
          :maxLength="200"
          v-decorator="['remark', { initialValue: record.remark || '' }]"
        ></a-textarea>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('remark') }}/200</span
        >
      </a-form-item>
      <a-form-item label="审核状态">
        <a-switch
          v-decorator="['enabled', { initialValue: record.enabled }]"
          checked-children="开"
          un-checked-children="关"
          :defaultChecked="record.enabled ? true : false"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { fullFileUpload } from '@/utils/newFileUpload.js'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'AddAdvertise',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
    channelList: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    // console.log(this.record)
    this.init()
    this.$nextTick(() => {
      this.$forceUpdate()
    })
  },
  data() {
    return {
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this),
      isEdit: Object.keys(this.record).length > 0,
      imageUrl: '',
      advertiseType: 0,
      clickType: 0,
    }
  },
  methods: {
    init() {
      if (this.isEdit) {
        this.imageUrl = this.record.imageUrl
        this.advertiseType = this.record.type || 0
        this.clickType = this.record.clickUrl ? 0 : 1
      }
    },
    /* 创建广告 */
    addAdvertise(channelIds, data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementCreated(channelIds, data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '创建广告')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改广告 */
    updateAdvertise(data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementUpdate(data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改广告')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    /* 广告类型切换 */
    advertiseTypeChange(e) {
      this.advertiseType = e.target.value
    },
    /* 点击是事件切换 */
    advertiseClickChange(e) {
      this.clickType = e.target.value
    },
    /* 上传检验 */
    imgCheck(rule, value, callback) {
      if (this.imageUrl) {
        return Promise.resolve()
      } else {
        return Promise.reject('请上传图片')
      }
    },
    handleOk() {
      // this.confirmLoading = true
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values, this.imageUrl)
          this.$set(values, 'enabled', values.enabled ? 1 : 0)
          // this.$set(values, 'status', values.status ? 1 : 0)
          this.$set(values, 'imageUrl', this.imageUrl)
          let { qrFile, ...data } = values
          if (values.clickType == 1) {
            data.clickUrl = ''
          }
          if (this.isEdit) {
            /* 编辑广告 */
            this.$set(data, 'id', this.record.id)
            if (this.advertiseType == 1) {
              this.$set(data, 'name', this.record.name)
            }
            this.updateAdvertise(data).then((res) => {
              this.$message.success('修改成功')
              this.$emit('refresh')
            })
          } else {
            /* 新增广告 */
            this.$set(data, 'tid', sessionStorage.getItem('tid'))
            this.addAdvertise(values.channelIds, data).then((res) => {
              this.$message.success('新增广告成功')
              this.$emit('refresh')
            })
          }
        }
      })
      // setTimeout(() => {
      //   this.confirmLoading = false
      //   this.$emit('refresh')
      // }, 2000)
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    _handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload(file) {
      console.log(file)
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif'
      if (!isJpgOrPng) {
        this.$message.error('文件格式不对')
      }
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      return isJpgOrPng
    },
    fileUpload(file) {
      // console.log(file, '文件')
      fullFileUpload(file.file)
        .then((res) => {
          // console.log(res)
          this.imageUrl = res.fileUrl
          this.loading = false
        })
        .catch(() => {
          this.imageUrl = ''
        })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .img {
  width: 102px;
  height: 102px;
}

/deep/ .ant-form-item {
  margin-bottom: 10px;
}

/deep/ .input_double {
  display: flex;
  align-items: center;
}
</style>
