<template>
  <a-layout>
    <a-layout-sider>
      <!-- 频道菜单 -->
      <div class="menu_title">
        <span>频道</span>
        <span @click="addChannel" style="cursor: pointer"
          ><a-icon type="plus"
        /></span>
      </div>
      <a-menu :selectedKeys="selectedKeys" @select="selectMenuItem">
        <a-menu-item
          v-for="item in channelList"
          :key="item.id"
          @click="clickMenuItem(item)"
        >
          <span>{{ item.name }}</span>
          <span class="count_right" v-if="item.adIds">{{
            JSON.parse(item.adIds).length
          }}</span>
          <a-dropdown
            class="mone-drop"
            v-if="selectedKeys.includes(item.id) && item.id !== '0'"
          >
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <img
                src="~@/assets/img/mone.png"
                alt=""
                class="mone"
                style="width: 20px"
              />
            </a>
            <a-menu slot="overlay" @click="channelOpn($event, item)">
              <a-menu-item key="1">
                <a-icon type="setting" />
                编辑
              </a-menu-item>

              <a-menu-item key="2">
                <a-icon type="delete" />
                删除
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>

    <a-layout-content>
      <!-- 内容 -->
      <div class="content_title">
        <span>广告</span>
        <a-button type="primary" icon="plus" @click="openAddAdvertise"
          >新增广告</a-button
        >
      </div>
      <!-- 广告表格 -->
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleTableChange"
        :rowKey="(record) => record.id"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <div slot="imageUrl" slot-scope="text">
          <img :src="text" alt="" class="ad_image" />
        </div>
        <span slot="enabled" slot-scope="text, record">
          <a-switch
            :key="record.id"
            v-if="againShow"
            checked-children="开"
            un-checked-children="关"
            :defaultChecked="record.enabled ? true : false"
            @change="switchChange($event, record)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="edit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="del(record)">删除</a>
        </span>
      </a-table>
    </a-layout-content>
    <!-- 批量操作条 -->
    <div class="opn" v-if="selectedRowKeys.length > 0">
      <a-checkbox @change="checkboxChange" :checked="checked"
        >选择所有</a-checkbox
      >
      <div class="opn-right">
        <div>已选{{ selectedRowKeys.length }}项，批量操作：</div>
        <div @click="batchMove"><a-button icon="drag">移动</a-button></div>
        <div>
          <a-button class="border: none" @click="cancel">取消</a-button>
        </div>
      </div>
    </div>
    <add-advertise
      :visible.sync="visible"
      :record="record"
      :channelList="channelList"
      v-if="visible"
      @refresh="refresh"
    ></add-advertise>
    <!-- 移动 -->
    <remove
      :visible.sync="removeVisible"
      v-if="removeVisible"
      @refresh="refresh"
      :channelList="channelList"
      :oldChannelId="oldChannelId"
      :selectedRowKeys="selectedRowKeys"
    ></remove>
    <!-- 新建、编辑频道 -->
    <channel
      :visible.sync="channelVisible"
      :record="channelRecord"
      v-if="channelVisible"
      @refresh="refresh"
    ></channel>
  </a-layout>
</template>

<script>
import addAdvertise from './addAdvertise.vue'
import remove from './remove.vue'
import channel from './channel.vue'

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'id' },
  },
  {
    title: '标题',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    scopedSlots: { customRender: 'imageUrl' },
  },
  {
    title: '描述',
    dataIndex: 'remark',
    key: 'remark',
    scopedSlots: { customRender: 'remark' },
  },
  {
    title: '审核状态',
    dataIndex: 'enabled',
    key: 'enabled',
    scopedSlots: { customRender: 'enabled' },
  },
  {
    title: '最近修改时间',
    dataIndex: 'updateTime',
    key: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    sorter: (a, b) => new Date(a.updateTime) - new Date(b.updateTime),
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    width: 120,
  },
]

export default {
  name: 'ChannelsAndAdvertiseTab',
  components: {
    addAdvertise,
    remove,
    channel,
  },
  data() {
    return {
      channelList: [
        {
          name: '频道1',
          id: 1,
        },
        {
          name: '频道2',
          id: 2,
        },
      ],
      visible: false,
      record: {},
      data: [],
      columns,
      selectedRowKeys: [],
      // pagination: false,
      removeVisible: false,
      selectedKeys: [],
      channelRecord: {},
      channelVisible: false,
      advertiseIds: [] /* 频道下的广告ids */,
      oldChannelId: '0' /* 当前频道 */,
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
      },
      againShow: true,
    }
  },
  computed: {
    checked() {
      if (this.selectedRowKeys.length == this.data.length) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getChannelList().then((res) => {
        // if (this.advertiseIds.length > 0) {

        //   this.getAdvertiseList(this.advertiseIds, { num: 0, size: 10 })
        // } else {
        //   this.getAdvertiseList(JSON.parse(this.channelList[0].adIds), {
        //     num: 0,
        //     size: 10,
        //   })
        //   this.selectedKeys = [this.channelList[0].id]
        // }

        /* 筛选当前渠道下线的广告 */
        this.advertiseIds = JSON.parse(
          this.channelList
            .filter((item) => {
              return item.id == this.oldChannelId
            })
            .map((item) => item.adIds)
        )
        if (this.advertiseIds.length === 0) {
          this.data = []
        } else {
          this.getAdvertiseList(this.advertiseIds, {
            num: this.pagination.current - 1,
            size: this.pagination.pageSize,
          }).then(() => {
            this.againShow = false
            this.$nextTick(() => {
              this.againShow = true
              // this.$forceUpdate()
            })
            if (this.data.length == 0) {
              this.getAdvertiseList(this.advertiseIds, {
                num: 0,
                size: 10,
              }).then(() => {
                this.againShow = false
                this.$nextTick(() => {
                  this.againShow = true
                  // this.$forceUpdate()
                })
              })
            }
          })
        }

        this.selectedKeys = [this.oldChannelId]
        // this.againShow = false
      })
    },
    /* 获取频道列表 */
    getChannelList() {
      return new Promise((resolve, reject) => {
        this.$api.channelList().then((res) => {
          // console.log(res, '频道列表')
          if (res.code === 0) {
            this.channelList = res.data

            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 获取广告列表 */
    getAdvertiseList(ids, data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementList(ids, data).then((res) => {
          // console.log(res, '广告列表')
          if (res.code === 0) {
            this.data = res.data.content
            this.pagination.total = Number(res.data.totalElements)
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    selectMenuItem({ item, key, select }, record) {
      // console.log(item, key, select, record)
      this.selectedKeys = [key]
      this.oldChannelId = key
      this.selectedRowKeys = []
    },
    clickMenuItem(record) {
      // console.log(record)
      let ids = JSON.parse(record.adIds)
      // console.log(ids)
      this.advertiseIds = ids
      // this.oldChannelId = record.id
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getAdvertiseList(ids, {
        num: this.pagination.current - 1,
        size: this.pagination.pageSize,
      }).catch(() => {
        this.data = []
      })
    },
    /* 删除频道 */
    deleteChannel(ids) {
      return new Promise((resolve, reject) => {
        this.$api.channelDel(ids).then((res) => {
          // console.log(res, '删除频道')
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    channelOpn({ key }, record) {
      // console.log(key, record)
      let _this = this
      if (key == 1) {
        /* 编辑 */
        this.channelRecord = record
        this.channelVisible = true
      } else if (key == 2) {
        /* 删除 */
        this.$confirm({
          title: '确定删除该条?',
          content: (h) => (
            <div style="color:red;">删除后不可恢复，请谨慎操作</div>
          ),
          onOk() {
            console.log('OK')
            _this.deleteChannel([record.id]).then((res) => {
              _this.$message.success('删除成功')
              _this.oldChannelId = '0'
              _this.refresh()
            })
          },
          onCancel() {
            // console.log('Cancel')
          },
          class: 'test',
        })
      }
    },
    addChannel() {
      this.channelRecord = {}
      this.channelVisible = true
    },
    cancel() {
      this.selectedRowKeys = []
    },
    checkboxChange(e) {
      // this.checked = e.target.checked
      if (e.target.checked) {
        this.selectedRowKeys = this.documentList.map((item) => {
          return item.id
        })
      } else {
        this.selectedRowKeys = []
      }
    },
    /* 批量移动广告 */
    batchMove() {
      // this.documentRecord = {}
      this.removeVisible = true
    },
    openAddAdvertise() {
      this.record = {}
      this.visible = true
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    handleTableChange({ current, pageSize }) {
      // console.log(current, pageSize)
      this.pagination.current = current
      this.pagination.pageSize = pageSize

      this.getAdvertiseList(this.advertiseIds, {
        num: current - 1,
        size: pageSize,
      })
    },
    switchChange(e, record) {
      console.log(e, record)
      let { enabled, ...data } = record
      this.$set(data, 'enabled', e ? 1 : 0)
      console.log(data)
      this.switchAdvertiseOnOff([data.id], data.enabled).then((res) => {
        this.$message.success('修改成功')
        this.init()
      })
    },
    /* 广告审核状态修改 */
    switchAdvertiseAudit(ids, status) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementAudit(ids, status).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改广告审核')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 广告审核状态修改(开关) */
    switchAdvertiseOnOff(ids, enabled) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementOnOff(ids, enabled).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改广告审核')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改广告 */
    updateAdvertise(data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementUpdate(data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改广告')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 删除广告 */
    delAdvertise(ids, channelId) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementDel(ids, channelId).then((res) => {
          if (res.code === 0) {
            // console.log(res, '删除广告')
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    },
    refresh() {
      this.record = {}
      this.channelVisible = false
      this.visible = false
      this.removeVisible = false
      this.selectedRowKeys = []
      this.init()
    },
    edit(record) {
      this.record = record
      this.visible = true
    },
    del(record) {
      // console.log(this.selectedKeys[0])
      // console.log(record)
      let _this = this
      this.$confirm({
        title: '确定删除该条?',
        content: (h) => (
          <div style="color:red;">删除后不可恢复，请谨慎操作</div>
        ),
        onOk() {
          console.log('OK')
          _this.delAdvertise([record.id], _this.selectedKeys[0]).then(() => {
            _this.$message.success('删除成功')
            _this.init()
          })
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },
  },
}
</script>

<style lang="less" scoped>
.ant-layout {
  background-color: #fff;
}
/deep/ .ant-layout-sider {
  background: #fff;
  border-right: 2px solid #c9c9c9;
  margin-right: 5px;
  width: 300px;
  padding: 8px;
}

.ant-layout-content {
  background-color: #fff;
}

.menu_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 8px;
  &::before {
    width: 4px;
    height: 20px;
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    margin-left: -15px;
    border-radius: 2px;
    background-color: #1890ff;
  }
}

.content_title {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 16px;
  &::before {
    width: 4px;
    height: 20px;
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    margin-left: -15px;
    border-radius: 2px;
    background-color: #1890ff;
  }
  span {
    margin-right: 15px;
  }
}

.opn {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-40%);
  width: 70%;
  height: 62px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 4px 14px #88888830;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .opn-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
  }
}

.mone {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.mone-drop {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.count_right {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.ad_image {
  // width: 150px;
  height: 65px;
}
</style>
