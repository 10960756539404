<template>
  <a-modal
    :visible="visible"
    :title="isEdit ? '编辑频道' : '新建频道'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item label="名称">
        <a-input
          :maxLength="30"
          v-decorator="[
            'name',
            {
              initialValue: record.name || '',
              rules: [{ required: true, message: '输入名称', trigger: 'blur' }],
            },
          ]"
        >
        </a-input>
        <span style="position: absolute; bottom: -11px; right: 10px"
          >{{ getItemValueLength('name') }}/30</span
        >
      </a-form-item>
      <a-form-item label="包含广告">
        <a-select
          v-decorator="['adIds', { initialValue: adIds || [] }]"
          mode="multiple"
        >
          <a-select-option v-for="item in advertiseList" :key="item.id">
            {{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'Remove',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      isEdit: Object.keys(this.record).length > 0,
      advertiseList: [],
      adIds: [],
    }
  },
  created() {
    this.init()
    // console.log(this.record, this.selectedRowKeys)
  },
  methods: {
    /* 修改频道 */
    updateChannel(data) {
      return new Promise((resolve, reject) => {
        this.$api.channelUpdate(data).then((res) => {
          // console.log(res, '修改频道')
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 添加频道 */
    addChannel(data) {
      return new Promise((resolve, reject) => {
        this.$api.channelCreated(data).then((res) => {
          // console.log(res, '添加频道')
          if (res.code === 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 获取输入框值长度 */
    getItemValueLength(val) {
      const value = this.form.getFieldsValue([val])[val]
      return value ? value.length : '0'
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values)
          if (this.isEdit) {
            /* 修改频道 */
            let data = {
              ...values,
              id: this.record.id,
            }
            this.updateChannel(data).then((res) => {
              this.$message.success('修改成功')
              this.$emit('refresh')
            })
          } else {
            /* 新增频道 */
            this.addChannel(values).then((res) => {
              this.$message.success('添加成功')
              this.$emit('refresh')
            })
          }
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },

    init() {
      this.getAdvertiseList()
      if (this.isEdit) {
        this.adIds = JSON.parse(this.record.adIds)
      }
    },

    /* 获取广告列表（全查） */
    getAdvertiseList() {
      return new Promise((resolve, reject) => {
        this.$api.advertisementListAll().then((res) => {
          if (res.code === 0) {
            // console.log(res, '广告列表全查')
            this.advertiseList = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
