<template>
  <div>
    <div style="margin-bottom: 10px">
      <a-button type="primary" icon="plus" @click="openAddAdvertisePosition"
        >新增广告位</a-button
      >
    </div>
    <!-- 广告位列表 -->
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="tableChange"
    >
      <span slot="name" slot-scope="text, record">
        {{ record.name }}
      </span>
      <span slot="size" slot-scope="text, record">
        {{ record.width }}*{{ record.height }}
      </span>
      <span slot="type" slot-scope="text"> {{ typeName[text] }} </span>
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          :key="record.id"
          v-if="againShow"
          checked-children="开"
          un-checked-children="关"
          :defaultChecked="record.enabled ? true : false"
          @change="switchChange($event, record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="edit(record)">编辑</a>
        <a-divider type="vertical" />
        <a @click="del(record)">删除</a>
      </span>
    </a-table>
    <!-- 添加、编辑广告位 -->
    <add-advertise-position
      v-if="visible"
      :record="record"
      :visible.sync="visible"
      @refresh="refresh"
    ></add-advertise-position>
  </div>
</template>

<script>
import addAdvertisePosition from './addAdvertisePosition.vue'

const columns = [
  {
    title: '唯一标识',
    dataIndex: 'uniqueKey',
    key: 'uniqueKey',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'uniqueKey' },
  },
  {
    title: '位置描述',
    dataIndex: 'position',
    key: 'position',
    scopedSlots: { customRender: 'position' },
  },

  {
    title: '规格大小',
    dataIndex: 'size',
    key: 'size',
    scopedSlots: { customRender: 'size' },
  },
  {
    title: '展现形式',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '状态',
    dataIndex: 'enabled',
    key: 'enabled',
    scopedSlots: { customRender: 'enabled' },
  },
  {
    title: '最近修改时间',
    dataIndex: 'updateTime',
    key: 'updateTime',
    scopedSlots: { customRender: 'updateTime' },
    sorter: (a, b) => new Date(a.updateTime) - new Date(b.updateTime),
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'AdvertisePosition',
  components: {
    addAdvertisePosition,
  },
  data() {
    return {
      visible: false,
      record: {},
      data: [],
      columns,
      typeName: {
        0: 'Banner',
        1: '弹窗',
        2: 'Feed流',
        3: '开屏',
        4: '公告',
      },

      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
      },
      againShow: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getAdvertisePositionList({
        num: this.pagination.current - 1,
        size: this.pagination.pageSize,
      }).then(() => {
        this.againShow = false
        this.$nextTick(() => {
          this.againShow = true
          // this.$forceUpdate()
        })
        if (this.data.length == 0) {
          this.getAdvertisePositionList({ num: 0, size: 10 })
        }
      })
    },
    refresh() {
      this.visible = false
      this.record = {}
      this.init()
    },
    tableChange({ current, pageSize }) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getAdvertisePositionList({ num: current - 1, size: pageSize })
    } /* 获取广告位列表 */,
    getAdvertisePositionList(data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementPositionList(data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '广告位列表')
            this.data = res.data.content
            this.pagination.total = Number(res.data.totalElements)
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 状态开关 */
    switchChange(e, record) {
      // console.log(e, record)
      // this.$set(record, 'enabled', e ? 1 : 0)
      let data = {
        width: record.width,
        height: record.height,
        channelIds: JSON.parse(record.channelIds),
        uniqueKey: record.uniqueKey,
        position: record.position,
        type: record.type,
        id: record.id,
        enabled: e ? 1 : 0,
      }

      // this.updateAdvertisePosition(data).then(() => {
      //   this.$message.success('修改成功')
      //   this.init()
      // })
      this.switchAdvertisePosition(data.enabled, [data.id]).then(() => {
        this.$message.success('修改成功')
        this.init()
      })
    },
    /* 广告位开关 */
    switchAdvertisePosition(enabled, ids) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementPositionOnOff(enabled, ids).then((res) => {
          if (res.code === 0) {
            // console.log(res, '开关广告位')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 修改广告位 */
    updateAdvertisePosition(data) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementPositionUpdate(data).then((res) => {
          if (res.code === 0) {
            // console.log(res, '修改广告位')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    /* 删除广告位 */
    delAdvertisePosition(ids) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementPositionDel(ids).then((res) => {
          if (res.code === 0) {
            // console.log(res, '删除广告位')
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    },
    openAddAdvertisePosition() {
      this.record = {}
      this.visible = true
    },
    edit(record) {
      this.record = record
      this.visible = true
    },
    del(record) {
      // console.log(record)
      let _this = this
      this.$confirm({
        title: '确定删除该条?',
        content: (h) => (
          <div style="color:red;">删除后不可恢复，请谨慎操作</div>
        ),
        onOk() {
          _this
            .delAdvertisePosition([record.id])
            .then((res) => {
              _this.$message.success('删除成功')
              _this.init()
            })
            .catch((err) => {
              console.log(err)
            })
        },
        onCancel() {
          // console.log('Cancel')
        },
        class: 'test',
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
