<template>
  <a-modal
    :visible="visible"
    title="移动"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <a-form-item label="移至">
        <a-select
          v-decorator="[
            'newChannelId',
            {
              rules: [{ required: true, message: '选择频道', trigger: 'blur' }],
            },
          ]"
        >
          <a-select-option
            v-for="item in channelList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'Remove',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    channelList: {
      type: Array,
      default: () => [],
    },
    selectedRowKeys: {
      type: Array,
      default: () => [],
    },
    oldChannelId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
    }
  },
  created() {
    this.init()
    // console.log(this.selectedRowKeys)
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(values, this.selectedRowKeys, this.oldChannelId)
          let params = {
            ...values,
            oldChannelId: this.oldChannelId,
            adIds: this.selectedRowKeys,
          }
          // console.log(params)
          this.moveAdvertise(params).then((res) => {
            // this.$message.success('移动成功')
            this.$emit('refresh')
          })
        }
      })
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },

    init() {},
    /* 广告迁移、移动 */
    moveAdvertise(params) {
      return new Promise((resolve, reject) => {
        this.$api.advertisementMove(params).then((res) => {
          if (res.code === 0) {
            // console.log(res, '迁移')
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped></style>
