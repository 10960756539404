var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑广告位' : '新增广告位',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"800px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"唯一标识"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'uniqueKey',
          {
            initialValue: _vm.record.uniqueKey || '',
            rules: [
              { required: true, message: '请输入唯一标识', trigger: 'blur' } ],
          } ]),expression:"[\n          'uniqueKey',\n          {\n            initialValue: record.uniqueKey || '',\n            rules: [\n              { required: true, message: '请输入唯一标识', trigger: 'blur' },\n            ],\n          },\n        ]"}],attrs:{"maxLength":30}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('uniqueKey'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"位置描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'position',
          {
            initialValue: _vm.record.position || '',
            rules: [
              { required: true, message: '输入位置描述', trigger: 'blur' } ],
          } ]),expression:"[\n          'position',\n          {\n            initialValue: record.position || '',\n            rules: [\n              { required: true, message: '输入位置描述', trigger: 'blur' },\n            ],\n          },\n        ]"}],attrs:{"maxLength":30}}),_c('span',{staticStyle:{"position":"absolute","bottom":"-11px","right":"10px"}},[_vm._v(_vm._s(_vm.getItemValueLength('position'))+"/30")])],1),_c('a-form-item',{attrs:{"label":"规格大小"}},[_c('div',{staticClass:"input_double"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'width',
            {
              initialValue: _vm.record.width || '',
              rules: [{ required: true, message: '输入宽度' }],
            } ]),expression:"[\n            'width',\n            {\n              initialValue: record.width || '',\n              rules: [{ required: true, message: '输入宽度' }],\n            },\n          ]"}],attrs:{"placeholder":"宽"}}),_c('a-icon',{staticStyle:{"margin-left":"10px","margin-right":"10px"},attrs:{"type":"close"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'height',
            {
              initialValue: _vm.record.height || '',
              rules: [{ required: true, message: '输入高度' }],
            } ]),expression:"[\n            'height',\n            {\n              initialValue: record.height || '',\n              rules: [{ required: true, message: '输入高度' }],\n            },\n          ]"}],attrs:{"placeholder":"高"}})],1)]),_c('a-form-item',{attrs:{"label":"展现形式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          {
            initialValue: _vm.record.type || 0,
            rules: [{ required: true, message: '选择形式' }],
          } ]),expression:"[\n          'type',\n          {\n            initialValue: record.type || 0,\n            rules: [{ required: true, message: '选择形式' }],\n          },\n        ]"}]},[_c('a-radio',{attrs:{"value":0}},[_vm._v(" Banner ")]),_c('a-radio',{attrs:{"value":1}},[_vm._v(" 弹窗 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" Feed流 ")]),_c('a-radio',{attrs:{"value":3}},[_vm._v(" 开屏 ")]),_c('a-radio',{attrs:{"value":4}},[_vm._v(" 公告 ")])],1)],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['enabled']),expression:"['enabled']"}],attrs:{"checked-children":"开","un-checked-children":"关","default-checked":_vm.record.enabled ? true : false}})],1),_c('a-form-item',{attrs:{"label":"推送对象"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'scope',
          {
            initialValue: _vm.record.pushConfig ? _vm.record.pushConfig.scope : 'ALL',
            rules: [{ required: true, message: '请选择点击事件' }],
          } ]),expression:"[\n          'scope',\n          {\n            initialValue: record.pushConfig ? record.pushConfig.scope : 'ALL',\n            rules: [{ required: true, message: '请选择点击事件' }],\n          },\n        ]"}],on:{"change":_vm.pushObjectChange}},[_c('a-radio',{attrs:{"value":"ALL"}},[_vm._v("所有用户")]),_c('a-radio',{attrs:{"value":"NEW_USER"}},[_vm._v("新用户（注册时间在3个月内）")]),_c('a-radio',{attrs:{"value":"OLD_USER"}},[_vm._v("老用户")]),_c('a-radio',{attrs:{"value":"TENANT_USER"}},[_vm._v("特定租户用户 "),(_vm.pushObjectType == 'TENANT_USER')?_c('span',{staticStyle:{"margin-left":"15px","color":"blue"},on:{"click":_vm.openSelectTenant}},[_vm._v("选择租户")]):_vm._e()])],1)],1),(_vm.pushObjectType == 'TENANT_USER')?_c('a-form-item',{attrs:{"label":"已选租户"}},_vm._l((_vm.selectedTenant),function(item){return _c('div',{key:item},[_vm._v(" "+_vm._s(item.name)+"("+_vm._s(item.id)+") ")])}),0):_vm._e(),_c('a-form-item',{attrs:{"label":"推送时间"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'pushTime',
          {
            initialValue: _vm.pushTimeType || 0,
            rules: [{ required: true, message: '请选择推送时间' }],
          } ]),expression:"[\n          'pushTime',\n          {\n            initialValue: pushTimeType || 0,\n            rules: [{ required: true, message: '请选择推送时间' }],\n          },\n        ]"}],on:{"change":_vm.pushTimeChange}},[_c('a-radio',{attrs:{"value":0}},[_vm._v("全年")]),_c('a-radio',{attrs:{"value":1}},[_vm._v("自定义")])],1)],1),(_vm.pushTimeType == 1)?_c('a-form-item',{attrs:{"label":""}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'time',
          {
            rules: [{ required: true, message: '请先择推送时间' }],
            initialValue: _vm.timeArr,
          } ]),expression:"[\n          'time',\n          {\n            rules: [{ required: true, message: '请先择推送时间' }],\n            initialValue: timeArr,\n          },\n        ]"}]})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"允许cookies记住关闭"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'isRemember',
          {
            initialValue: _vm.record.isRemember || false,
            valuePropName: 'checked',
          } ]),expression:"[\n          'isRemember',\n          {\n            initialValue: record.isRemember || false,\n            valuePropName: 'checked',\n          },\n        ]"}],attrs:{"checked-children":"开","un-checked-children":"关"}})],1),_c('a-form-item',{attrs:{"label":"配置频道和广告"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'channelIds',
          {
            initialValue: _vm.record.channelIds
              ? JSON.parse(_vm.record.channelIds) || []
              : [],
          } ]),expression:"[\n          'channelIds',\n          {\n            initialValue: record.channelIds\n              ? JSON.parse(record.channelIds) || []\n              : [],\n          },\n        ]"}],attrs:{"placeholder":"请选择频道（可多选）","mode":"multiple"}},_vm._l((_vm.channelList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(_vm.openVisible)?_c('my-transfer',{attrs:{"visible":_vm.openVisible,"selectedIds":_vm.tenantIds,"title":"选择租户"},on:{"update:visible":function($event){_vm.openVisible=$event},"refresh":_vm.refresh,"ok":_vm.transferOk}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }