var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"ok-text":"保存","width":"850px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.title))]),_c('a-transfer',{attrs:{"titles":['选择租户', '已选择租户'],"data-source":_vm.allocationData,"target-keys":_vm.targetKeys,"show-search":"","filter-option":_vm.filterOption,"rowKey":function (record) { return record.id; },"list-style":{
      width: '350px',
      height: '950px',
    }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"children",fn:function(ref){
      var ref_props = ref.props;
      var direction = ref_props.direction;
      var filteredItems = ref_props.filteredItems;
      var selectedKeys = ref_props.selectedKeys;
      var listDisabled = ref_props.disabled;
      var ref_on = ref.on;
      var itemSelectAll = ref_on.itemSelectAll;
      var itemSelect = ref_on.itemSelect;
return [_c('a-table',{attrs:{"pagination":_vm.pagination,"row-selection":_vm.getRowSelection({
            disabled: listDisabled,
            selectedKeys: selectedKeys,
            itemSelectAll: itemSelectAll,
            itemSelect: itemSelect,
          }),"columns":_vm.nameColumns,"data-source":filteredItems,"custom-row":function (ref) {
              var key = ref.key;
              var itemDisabled = ref.disabled;

              return ({
            on: {
              click: function () {
                if (itemDisabled || listDisabled) { return }
                itemSelect(key, !selectedKeys.includes(key))
              },
            },
          });
      }},on:{"change":_vm.tenantTableChange},scopedSlots:_vm._u([{key:"rentname",fn:function(text, record){return [_vm._v(" "+_vm._s(text || record.name)+" ("+_vm._s(record.id)+") ")]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }